import React, { useEffect } from "react"
import { PageProps, navigate } from "gatsby"

import Layout from "@/components/Layout/PageRoot"
import PageContent from "@/components/Layout/PageContent"
import SEO from "@/components/Layout/SEO"
import Footer from "@/components/Common/Footer"
import Landing from "@/components/Home/Header"
import Section from "@/components/Home/Section"
import Info from "@/components/Home/Info"
import Events from "@/components/Home/Events"
import JumpLinks from "@/components/Home/JumpLinks"
import Sponsors from "@/components/Home/Sponsors"
import ScrollUpButton from "@/components/Home/ScrollUpButton"
import { setPageLang } from "@/utils/i18n"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "ja";
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang || lang === "") return "ja";

  switch (lang.toLowerCase()) {
    case "ja": case "jp": case "jpn":
      return "ja";
    default:
      return "en";
  }
};

const Index = () => {
  setPageLang('ja');
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    // Default index page is Japanese, don't bother redirecting
    if (urlLang === "ja") {
      return;
    }

    navigate(`/${urlLang}/`, {replace: true});
  }, []);

  return (
    <Layout>
      <SEO
        title="Home"
        description="Esports Beyondは個人、渋谷区、企業がesportsを中心に、教育、福祉、地域創生、産業振興につながるシステムと、新たなカルチャーを生み出す企画などを生み出すプロジェクトです。"
        lang="ja"
      />
      <PageContent>
        <Landing />
        <Section
          style={{
            background: "#1c1f1f",
            zIndex: 2,
          }}
        >
          <Info />
        </Section>
        <Events />
        <Sponsors />
        <ScrollUpButton />
      </PageContent>
      <Footer />
    </Layout>
  )
}

export default Index
